import React, { useState, useEffect } from "react";
import Header from "../components/layout/Header";

import DataTable from "react-data-table-component";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { CSVLink } from "react-csv"; // Import CSVLink from 'react-csv'

function TryToBook() {
  const [searchItems, setSearchItems] = useState("");
  const [Remarks, setremarks] = useState("");
  const [totalRecords, setTotalRecords] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterdata, setfilterdata] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setdata] = useState();
  const admin = JSON.parse(localStorage.getItem("admin"));

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setdata(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://vijayhomeservicebangalore.in/api/getcustomerdatapagewise?page=${currentPage}&search=${searchItems}`
        );
        const result = await response.json();

        setfilterdata(result?.customers);
        setTotalRecords(result?.totalRecords);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, searchItems]);

  const deleteuser = async (id) => {
    axios({
      method: "post",
      url: "https://api.vijayhomesuperadmin.in/api/deletetercustomer/" + id,
    })
      .then(function (response) {
        console.log(response);
        alert("Deleted successfully");
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error.response.data);
      });
  };

  const updateremarks = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: `/updatedRemarks/${data._id}`,
        method: "post",
        baseURL: "https://api.vijayhomesuperadmin.in/api",
        headers: { "content-type": "application/json" },
        data: {
          Remarks: Remarks,
          executive: admin?.displayname,
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          alert("Successfully updated remarks");
          window.location.reload("");
        }
      });
    } catch (error) {
      console.error(error);
      alert("Not Added");
    }
  };

  const columns = [
    {
      name: "Sl  No",
      selector: (row, index) => (currentPage - 1) * 15 + index + 1,
    },
    {
      name: "Create Date&Time",
      selector: (row, index) => (
        <div>
          <div>{moment(row.createdAt).format("DD-MM-YYYY ,LT")}</div>
        </div>
      ),
    },
    {
      name: "Update Date&Time",
      selector: (row, index) => (
        <div>
          <div>{moment(row.updatedAt).format("DD-MM-YYYY ,LT")}</div>
        </div>
      ),
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email || "-",
      wrap: true,
    },
    {
      name: "Contact",
      selector: (row) => row.mainContact,
      wrap: true,
    },
    {
      name: "TryToBook",
      selector: (row) => row.service || "-",
      wrap: true,
    },
    {
      name: "Reference",
      selector: (row) => row.reference || "-",
      wrap: true,
    },
    {
      name: "Executive",
      selector: (row) => row.executive || "-",
      wrap: true,
    },
    {
      name: "Remarks",
      selector: (row) => (row.Remarks ? row.Remarks : "-"),
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            onClick={() => handleShow(row)}
            className="hyperlink mx-1"
            style={{
              background: "lightgrey",
              padding: "7px",
              cursor: "pointer",
            }}
          >
            Remarks
          </a>
        </div>
      ),
    },
  ];

  // Prepare data for CSV
  const csvData = filterdata.map((row, index) => ({
    "Sl No": (currentPage - 1) * 15 + index + 1,
    "Create Date&Time": moment(row.createdAt).format("DD-MM-YYYY ,LT"),

    "Customer Name": row.customerName,
    Email: row.email || "-",
    Contact: row.mainContact,
    TryToBook: row.service || "-",
    Reference: row.reference || "-",
    Executive: row.executive || "-",
    Remarks: row.Remarks || "-",
  }));

  return (
    <div className="web">
      <Header />
      <div className="row mt-2 m-auto">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="header-text1">Try to booking customer data</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-auto">
        <div className="col-md-12">
          <div className="mt-5">
            <input
              type="text"
              placeholder="Search by name /email"
              className="w-25 form-control"
              value={searchItems}
              onChange={(e) => setSearchItems(e.target.value)}
            />
          </div>
          <div className="mt-3" style={{ textAlign: "right" }}>
            <CSVLink
              data={csvData}
              filename={`customer_data_${moment().format(
                "YYYYMMDD_HHmmss"
              )}.csv`}
              className="custom-export-btn"
            >
              Export to CSV
            </CSVLink>
          </div>
          <div className="mt-1 border">
            <DataTable
              columns={columns}
              data={filterdata}
              pagination
              paginationServer
              paginationTotalRows={totalRecords}
              paginationPerPage={15}
              paginationRowsPerPageOptions={[15, 30, 50]}
              onChangePage={(current) => setCurrentPage(current)}
              selectableRowsHighlight
              subHeaderAlign="left"
              highlightOnHover
            />
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Remarks</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                placeholder="Remarks"
                className="w-100 form-control"
                value={Remarks}
                onChange={(e) => setremarks(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={updateremarks}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default TryToBook;
